@import '../../../mediaQueries';
@import '../../../mixins';
@import '../../../colorThemes';

.subscriptionPlan_container{
  .subscriptionPlan_wrapper{
    .subscriptionPlan_main{
      padding: 25px;

      &-grid{
        padding: 1.5rem;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-column-gap: 2%;
        background-color: $appThemeWhite;

        &-plan{
          margin: 1rem 0;
          border-radius: 10px;
          padding: 2rem 1.5rem;
          background-color: $appThemeBackground;
          text-align: center;
          position: relative;
          overflow: hidden;
          .ribbon-1 {
            position: absolute;
            inset: 0 auto auto 0;
            background: $appThemeWhite;
            transform-origin: 100% 0;
            transform: translate(-29.3%) rotate(-45deg);
            box-shadow: 0 0 0 999px $appThemeWhite;
            clip-path: inset(0 -100%);
            font-weight: 600;
          }
          &_icon,&_type,&_price{
              margin-bottom: 1rem;
              font-weight: 500;
          }
          &_type{
              font-size: 1.3rem;
          }
          &_price{
              font-size: 1.1rem;
              color: $appThemeDark;
              font-weight: 600;
          }
          &_buy{
              margin: auto;
              // width: 60%;
              margin-bottom: 1rem;
          }
          &_content{
              line-height: 1.5rem;
              font-size: 0.9rem;
              font-weight: 500;
          }
        }

        .active_plan{
          background-color: $appThemeDark;
          -webkit-box-shadow: 0px 0px 17px 5px rgba(83,103,253,0.46);
          -moz-box-shadow: 0px 0px 17px 5px rgba(83,103,253,0.46);
          box-shadow: 0px 0px 17px 5px rgba(83,103,253,0.46);
          .subscriptionPlan_main-grid-plan{
            &_icon,&_type,&_price{
              color: $appThemeWhite;
            }
            &_content{
              color: $appThemeWhite;
            }
            &_buy{
              .active_plan-text{
                font-size: 1rem;
                font-weight: bolder;
                // margin: 1rem;
                color: #30cdfb;
                width: unset;
              }
            }
          }
        }
      }

      &-header_container{
        padding: 0 25px;
        @include flex-center-spacebetween;
        .subscriptionPlan_main-header{
          max-width: 42%;
          color: $appThemeText;
          &_text{
            font-size: clamp(1rem, 0.8rem + 1vw, 2rem);
            text-transform: capitalize;
            font-weight: 500;
          }
          @include flex-center-spacebetween;
        }
      }

      &-list_table{
        margin: 20px 0;
      }

      &-renewal{
        margin: 25px;
        background-color: $appThemeBackground;
        &_wrapper{
          @include flex-center-spacebetween;
          padding: 25px;
          .renewal_heading{
            margin: 10px;
            color: $appThemeDark;
          }
          .renewal_subHeading{
            margin: 10px;
          }
        }
      }

      &-addCard{
        padding: 25px;
        margin-right: 25px;
        @include flex-center-flexend;
      }
    }
  }
}

//smaller screens
@include media('<=desktop') {
  .subscriptionPlan_container{
    .subscriptionPlan_wrapper{
      .subscriptionPlan_main{
        &-grid{
  
          .item{
            padding: 10px;
  
            .item_content{
              padding: 10px;
            }
          }
        }
        &-list_table{
          margin: 15px 0;
        }
      }
    }
  }
}

@include media('<=tablet') {
  .subscriptionPlan_container{
    .subscriptionPlan_wrapper{
      .subscriptionPlan_main{
        padding: 20px;
        &-grid{
          // max-width: 75vw;
          display: grid;
          grid-template-columns: repeat(2, auto);
          grid-gap: 20px;
          padding: 20px 0;
  
          .item{
            // @include flex-center;
            // max-width: 25vw;  
            padding: 15px;
            background-color: $appThemeBackground;
            text-align: center;
  
            .item_content{
              padding: 15px;
              .content_text{
                // font-size: clamp(1rem, 0.7857rem + 0.5714vw, 1.5rem);
              }
  
              .content_number{
                font-size: clamp(3rem, 2.5000rem + 1.3333vw, 4rem);
                color: #5774F9;
              }
            }
          }
        }
        &-list_table{
          margin: 20px 0;
        }
      }
    }
  }
}

@include media('<=phone') {
  .subscriptionPlan_container{
    .subscriptionPlan_wrapper{
      .subscriptionPlan_main{
        &-message{
          @include flex-center-center;
        }
  
        &-grid{
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(4, auto);
  
          .item{
            padding: 15px;
          }
        }
  
        &-listing_header{
          margin-top: 10px;
          @include flex-center-center;
        }
  
        &-list_table{
          margin: 10px 0;
        }
      }
    }
  }
}