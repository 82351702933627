@import '../../../mixins.scss';
@import '../../../mediaQueries';
@import '../../../colorThemes';

.sidebar_container{
    padding: 0 20px;
    min-height: 100%;
    width: 20vw;
    background-color: $appThemeBackground;
    
    .sidebar_wrapper{
        padding:  0 10px 0 20px;

        .sidebar_main{
            &-submit, &-dashboard, &-jobroles, &-notifications{
                text-decoration: none;
                border-radius: 5px;
                margin-bottom: 20px;
                height: 3rem;
                width: 100%;
                @include flex-center-flexstart;
                // color: white;
                font-size: clamp(0.5rem, 0.2000rem + 0.8000vw, 1.2rem);
                font-weight: 500;
                word-break: break-word;
                svg{
                    margin: 0 5px 0 7px;
                    font-size: clamp(1rem, 0.9177rem + 0.5063vw, 1.5rem);
                }
            }
    
            .active{
                background: $appThemeLight;
                color: $appButtonHoverDark;
            }
            
            &-jobroles, &-notifications, &-dashboard{
                margin-bottom: unset;
                box-sizing: border-box;
                color: $appThemeText;
                background: transparent;
            }

            .disabled{
                color: gray;
                cursor: default;
            }
    
        }
    }
}

// @include media('<=desktop') {
//     .sidebar_container{
//         padding: 0 20px;
//         width: 20vw;
//         background-color: #EEF1FA;
        
//         .sidebar_wrapper{
//             padding:  0 20px;
    
//             .sidebar_main{
//                 &-submit, &-dashboard, &-jobroles, &-notifications{
//                     height: 3rem;
//                     svg{
//                     }
//                 }
        
//                 .active{
//                 }
                
//                 &-jobroles, &-notifications, &-dashboard{
//                 }
        
//             }
//         }
//     }
// }

@include media('<=tablet') {
    .sidebar_container{
        padding: 0 20px;
        
        .sidebar_wrapper{
            padding: unset;
            .sidebar_main{
                &-submit, &-dashboard, &-jobroles, &-notifications{
                    height: 2.5rem;
                    svg{
                        margin: 0 5px;
                    }
                }
            }
        }
    }
}

@include media('<=tablet') {
    .sidebar_container{
        padding: 0 20px;
        
        .sidebar_wrapper{
            padding: unset;
            .sidebar_main{
                &-submit, &-dashboard, &-jobroles, &-notifications{
                    height: 2.5rem;
                }
            }
        }
    }
}


//cellphones
@include media('<=phone') {
    .sidebar_container{
        display: none;
    }
}