@import '../../../mediaQueries';
@import '../../../mixins';
@import '../../../colorThemes';

.reports_container{
  .reports_wrapper{
    .reports_main{
      padding: 20px;
      &-message{
        margin: 10px;
        font-size: clamp(1.1rem, 0.4203rem + 1.4215vw, 2.2rem);
      }

      &-header_container{
        margin: 10px;
        margin-bottom: 30px;
        @include flex-center-spacebetween;
        .reports_main-listing_header{
          max-width: 42%;
          color: $appThemeText;
          &_text{
            font-size: clamp(1rem, 0.8rem + 1vw, 2rem);
            text-transform: capitalize;
          }
          @include flex-center-spacebetween;
        }
      }

      &-charts{
        width: 100%;
        @include flex-center-spacebetween;
        height: 100%;
        margin-bottom: 1rem;
        &_doughnut{
          width: 30%;
          // height: 50vh;
          &-header{
            margin-top: 1rem;
            text-align: center;
          }
        }
        &_bar{
          width: 60%;
          // min-height: 50vh;
          &-header{
            text-align: center;
          }
        }
      }

      &-generateReports{
        width: 70%;
        margin: auto;
        border-top: 5px solid gray;
        &_header{
          padding: 1rem;
          text-align: center;
          .generateReport-dateRange{
            padding: 1rem;
            text-align: center;
            width: 60%;
            min-width: 250px;
            margin: auto;
          }
        }
        &_generate{
          .generate_reports{
            @include flex-center-spacebetween;
            padding: 1rem;
            &-label{
              font-weight: 500;
              @include flex-center-center;
            }
            &-btn{
              @include flex-center-center;
            }
          }
        }
      }
    }
  }
}

//smaller screens
@include media('<=desktop') {
  .reports_container{
    .reports_wrapper{
      .reports_main{
        &-grid{
          gap: 20px;
  
          .item{
            padding: 10px;
  
            .item_content{
              padding: 10px;
            }
          }
        }
        &-list_table{
          margin: 15px 0;
        }
      }
    }
  }
}

@include media('<=tablet') {
  .reports_container{
    .reports_wrapper{
      .reports_main{
        padding: 20px;
        &-message{
          // font-size: clamp(1.3rem, 0.5203rem + 1.7215vw, 2.5rem);
        }
  
        &-grid{
          // max-width: 75vw;
          display: grid;
          grid-template-columns: repeat(2, auto);
          grid-gap: 20px;
          padding: 20px 0;
  
          .item{
            // @include flex-center;
            // max-width: 25vw;  
            padding: 15px;
            background-color: $appThemeBackground;
            text-align: center;
  
            .item_content{
              padding: 15px;
              .content_text{
                // font-size: clamp(1rem, 0.7857rem + 0.5714vw, 1.5rem);
              }
  
              .content_number{
                font-size: clamp(3rem, 2.5000rem + 1.3333vw, 4rem);
                color: #5774F9;
              }
            }
          }
        }
        &-list_table{
          margin: 20px 0;
        }
      }
    }
  }
}

@include media('<=phone') {
  .reports_container{
    .reports_wrapper{
      .reports_main{
        &-message{
          @include flex-center-center;
        }
  
        &-grid{
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(4, auto);
  
          .item{
            padding: 15px;
          }
        }
  
        &-listing_header{
          margin-top: 10px;
          @include flex-center-center;
        }
  
        &-list_table{
          margin: 10px 0;
        }
      }
    }
  }
}


.ant-picker-input>input::placeholder {
  color: $appThemeText !important;
  font-weight: 500;
  font-size: 1rem;
}

.ant-picker-input>input {
  height: 2rem !important;
  font-weight: 500;
}