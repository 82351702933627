@import '../../../mediaQueries';
@import '../../../mixins.scss';
@import '../../../colorThemes';

.header-container{
    padding: 20px;
    background-color: $appThemeBackground;
    .header-wrapper{
        color: $appThemeText;
        @include flex-center-spacebetween;
        padding: 20px;
        
        @include media('<=tablet') {
            padding: unset;
        }


        .header-logo{
            width: 10rem;
            cursor: pointer;
            &-image{
                width: inherit;
            }
        }

        .header-icons{
            @include flex-center-center;

            &-search>p, &-help>p{
                margin-left: 5px;
            }

            &-notifications, &-help{
                cursor: pointer;
                position: relative;
                font-size: clamp(1rem, 0.6164rem + 1.0959vw, 1.5rem);

                &-alert{
                    position: absolute;
                    bottom: 14px;
                    left: 8px;
                    background-color: red;
                    height: 1.1rem;
                    width: 1.1rem;
                    padding: 2px;
                    color: white;
                    border-radius: 100px;
                    p{
                        @include flex-center-center;
                        font-size: 0.9rem;
                        font-weight: 600;
                    }
                }
            }

            &-profile{
                position: relative;
                height: 1.6rem;
                width: 1.6rem;
                padding: 5px;
                background: $appThemeDark;
                border-radius: 50px;
                cursor: pointer;
                font-size: 1rem;
                color: $appThemeWhite;
                font-weight: 600;
                .header-logout_menu{
                    -webkit-box-shadow: 0px 0px 10px 0px rgba(104,106,110,1);
                    -moz-box-shadow: 0px 0px 10px 0px rgba(104,106,110,1);
                    box-shadow: 0px 0px 10px 0px rgba(104,106,110,1);
                    position: absolute;
                    top: 120%;
                    &-wrapper{
                        max-width: max-content;
                        padding: 8px;
                        color: $appThemeText;
                        &:hover{
                            color: $appThemeWhite;
                            background-color: $appThemeDark;
                        }
                    }
                }
            }
        }

        .header-icons>div{
            // font-size: clamp(1rem, 0.6164rem + 1.0959vw, 2rem);
            margin-left: 25px;
            @include flex-center-center;
        }
    }
}

//for cellphones 

@include media('<=phone') {
    .header-container{
        padding: 10px 5px;
        max-width: 100vw;
        .header-wrapper{
            padding: 10px 5px;
            
            .header-logo{
                width: 30vw;
                &-image{
                    width: 100%;
                }
                padding-right: 10px;
            }

            .header-icons{
                width: 40vw;
                justify-content: space-between;

                &-profile{
                    height: 1.4rem;
                    width: 1.4rem;
                }
    
                &-search>p, &-help>p{
                    margin-left: 2px;
                    display: none;
                }
            }
    
            .header-icons>div{
                font-size: 1.5rem;
                margin-left: unset;
                justify-items: space-between;
            }
        }
    }
}