@import '../../../../colorThemes';
@import '../../../../mixins';


.interviewDrawer{
    &-container{
        width: 38%;
        height: 100%;
        margin-right: 10px;
        background-color: $appThemeWhite;
        color: $appThemeText;
        overflow-y: auto;
        .interviewDrawer-wrapper{
            padding: 1.2rem;
            .interviewDrawer-content{
                &_header{
                    text-align: center;
                    margin: 1.2rem 0;
                    .header{
                        font-weight: 600;
                    }
                }
                &_form{
                    margin: auto;
                    .interviewDrawer-form_input_wrapper{
                        @include flex-center-spacebetween;
                        &-label{
                            padding: 1rem;
                            font-weight: bold;
                            max-width: 25%;
                        }
                        &-input{
                            font-weight: 400;
                            .view_textarea-text{
                                @include flex-center-center;
                                padding: unset;
                                margin-left:20px;
                                cursor: pointer;
                                span{
                                    //    @include flex-center-center; 
                                    font-size: 1.5rem;
                                }
                            }
                        }
                    }
                }
                &_notes{
                    .notes-header{
                        padding: 1.2rem 1rem;
                        color: $appThemeDark;
                        text-align: center;
                    }
                }
            }
        }
    }
}