@import "../../../colorThemes";
@import "../../../mixins";

.assign_candidate{
    // border: 20px solid opacify($color: #000000, $amount: 0);
    position: relative;
    background: $appThemeWhite;
    overflow: visible;
    height: max-content;
    min-height: 22rem;
    padding: 1rem;
    &-wrapper{
        max-height: 100%;
        padding: .5rem;
        height: 100%;
        // overflow: scroll;
        /* width */
        ::-webkit-scrollbar {
            width: 10px;
            border-radius: 50px;
        }
        
        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 50px;
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: $appButtonHoverDark;
            border-radius: 50px;
        }
        
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        &-heading_main{
            text-align: center;
            padding: 1rem;
            font-size: 2rem;
            font-weight: 500;
        }

        &-select_bp{
            padding: 1rem;

            &-submit{
                @include flex-center-center;
                margin-top: 4rem;
            }
        }
    }

    &-close{
        position: absolute;
        top: 0%;
        right: 0%;
    }
}

.dialogue_box-close{
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 8px;
    color: $appThemeWhite;
    border-radius: 50%;
    background-color: $appThemeDark;
    @include flex-center-center;
}

.bold-text{
    font-size: 1.2rem;
    font-weight: bold;
}