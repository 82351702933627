@import '../../../colorThemes';
@import '../../../mediaQueries';

.homeLayout-container{
    .homeLayout-wrapper{
        .homeLayout-header{
            max-width: 100vw;
        }

        .homeLayout{
            display: flex;

            .homeLayout-screen{
                min-height: calc(100vh - 177.691px);
                border-bottom: 2rem solid $appThemeBackground;
            }

            &-sidebar_main{
            }

            &-screen{
                width: 100%;
            }

            &-right{
                background-color: #EEF1FA;
                .homeLayout-right-main{
                    width: 3vw;
                }
            }
            // &-end-border{
                // height: 3rem;
                // background-color: $appThemeBackground;
                // border-bottom: 2rem solid $appThemeBackground;
            // }
        }
    }
}

//cellphones
@media (max-width: 600px) {
    .homeLayout-right{
        display: none;
    }
}