@import '../../../mediaQueries';
@import '../../../mixins';
@import '../../../colorThemes';

.productFeature_container{
  padding: 1rem;
  .productFeature_wrapper{
    padding: 1rem;
    .productFeature_main{
      padding: 1rem;

      &-header_container{
        text-align: center;
        padding: 1rem;
        margin-bottom: 2rem;
        .productFeature_main-header{
          margin-bottom: 0.8rem;
          color: $appThemeText;
          &_text{
            text-transform: capitalize;
            font-weight: 600;
          }
        }
        .productFeature_main-sub_header{
          color: $appThemeDark;
          &_text{
            text-transform: capitalize;
          }
        }
      }
      .productFeature_main-content{
        .productFeature_main-submitCandidate,.productFeature_main-manageCandidate {
          @include flex-center-spacebetween;
          width: 100%;
          margin-bottom: 3rem;
          .productFeature_main-product_image{
            width: 25%;
            @include flex-center-flexstart;
            flex-direction: inherit;
            img{
              height: 15rem;
              width: 15rem;
            }
          }
          .productFeature_main-product_info{
            width: 65%;
            &-header{
              color: $appThemeDark;
              font-size: 1.2rem;
              font-weight: 100;
              margin-bottom: 1rem;
              width: min-content;
            }
            &-text{
              p{
                line-height: 1.2rem;
                color: $appThemeText;
              }
            }
            
          }
        }
        .productFeature_main-manageCandidate{
          @include flex-center-center;
          flex-direction: row-reverse;
        }
      }
      &-buySubscription{
        &_wrapper{
          width: 100%;
          position: relative;
          img{
            max-width: 100%;
          }
          .productFeature_main-buySubscription_content{
            position: absolute;
            top: 20%;
            right: 6%;
            color: $appThemeWhite;
            font-weight: 600;
            width: 60%;
            &-header{
              padding: 3px;
              font-size: 1.5rem;
            }
            &-text{
              padding: 3px;
              font-size: 1rem;
              line-height: 1.2rem;
              word-wrap: break-word;
            }
            &-btn{
              color: $appThemeDark;
              padding: 3px;
            }
          }
        }
      }
    }
  }
}

//smaller screens
@include media('<=laptop') {
  .productFeature_container{
    .productFeature_wrapper{
      .productFeature_main{
        .productFeature_main-content{
          .productFeature_main-submitCandidate,.productFeature_main-manageCandidate {
            .productFeature_main-product_image{
              img{
                height: 13rem;
                width: 13rem;
              }
            }
            .productFeature_main-product_info{
              &-header{
                font-size: 1rem;
              }
              &-text{
                p{
                  line-height: 1rem;
                }
              }
              
            }
          }
        }
        &-buySubscription{
          &_wrapper{
            .productFeature_main-buySubscription_content{
              position: absolute;
              top: 12%;
              right: 6%;
              color: $appThemeWhite;
              font-weight: 600;
              width: 60%;
              &-header{
                padding: 2px;
                font-size: 1.2rem;
              }
              &-text{
                padding: 2px;
                font-size: 0.8rem;
                line-height: 1rem;
                word-wrap: break-word;
              }
              &-btn{
                color: $appThemeDark;
                padding: 2px;
              }
            }
          }
        }
      }
    }
  }
}

@include media('<=tablet') {
  .productFeature_container{
    .productFeature_wrapper{
      .productFeature_main{
        padding: 20px;
        &-grid{
          // max-width: 75vw;
          display: grid;
          grid-template-columns: repeat(2, auto);
          grid-gap: 20px;
          padding: 20px 0;
  
          .item{
            // @include flex-center;
            // max-width: 25vw;  
            padding: 15px;
            background-color: $appThemeBackground;
            text-align: center;
  
            .item_content{
              padding: 15px;
              .content_text{
                // font-size: clamp(1rem, 0.7857rem + 0.5714vw, 1.5rem);
              }
  
              .content_number{
                font-size: clamp(3rem, 2.5000rem + 1.3333vw, 4rem);
                color: #5774F9;
              }
            }
          }
        }
        &-list_table{
          margin: 20px 0;
        }
      }
    }
  }
}

@include media('<=phone') {
  .productFeature_container{
    .productFeature_wrapper{
      .productFeature_main{
        &-message{
          @include flex-center-center;
        }
  
        &-grid{
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(4, auto);
  
          .item{
            padding: 15px;
          }
        }
  
        &-listing_header{
          margin-top: 10px;
          @include flex-center-center;
        }
  
        &-list_table{
          margin: 10px 0;
        }
      }
    }
  }
}