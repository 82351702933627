@import '../../../mediaQueries';
@import '../../../mixins';
@import '../../../colorThemes';

.myAccount-container{
    .myAccount-wrapper{
        .myAccount_main{
            padding: 20px;
            max-width: 60%;
            margin: auto;
            
            &-header_container{
                @include flex-center-center;

                .myAccount_main-listing_header{
                    color: $appThemeText;
                    @include flex-center-spacebetween;
                    margin: 10px;

                    &_text{
                        font-size: clamp(1.2rem, 0.9250rem + 1.2222vw, 2.3rem);
                        text-transform: capitalize;
                    }
                }
            }

            &-inputs{
                margin: 2rem 0;
                &_wrapper{
                    margin: 20px;
                    @include flex-center-spacebetween;

                    .account_main-input_label{
                        max-width: 45%;
                        font-size: 1rem;
                        font-weight: 600;
                    }
                    .account_main-input{
                        width: 50%;
                        min-width: 20rem;
                    }
                }
            }

            &-action{
                @include flex-center-center;
                flex-direction: column;
                padding: 10px;
                &_button{
                    margin: 10px;
                    max-width: 50%;
                    min-width: 30%;
                }
            }
        }
    }

    &-separation{
        height: 3rem;
        background-color: $appThemeBackground;
    }
}

//smaller screens
@include media('<=desktop') {
    
}

@include media('<=tablet') {

}

@include media('<=phone') {

}