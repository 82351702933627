@import '../../../mediaQueries';
@import '../../../mixins';
@import '../../../colorThemes';

.view_candidate-container{
    .view_candidate-wrapper{
        .view_candidate_main{
            padding: 20px;
            max-width: 60%;
            margin: auto;
            
            &-header_container{
                @include flex-center-center;

                .view_candidate_main-listing_header{
                    color: $appThemeText;
                    @include flex-center-spacebetween;
                    margin: 10px;

                    &_text{
                        font-size: clamp(1.2rem, 0.9250rem + 1.2222vw, 2.3rem);
                        text-transform: capitalize;
                    }
                }
            }

            &-inputs,&-assessment_listing{
                margin: 2rem 0;
                &_wrapper{
                    margin: 20px;
                    @include flex-start-spacebetween;

                    .candidate_main-label{
                        padding: 5px;
                        max-width: 45%;
                        font-size: 1rem;
                        font-weight: 600;
                    }
                    .candidate_main-input{
                        width: 50%;
                        min-width: 20rem;
                        color: $appButtonHoverDark;
                        &_candidate_resume-actions{
                            @include flex-center-spacebetween;
                            font-size: 0.8rem;
                            &-btn{
                                max-width: 50%;
                                min-width: 30%;
                            }

                            span{
                                font-size: 1rem;
                                @include flex-center-center
                            }

                            .resume-path{
                                margin: 0 10px;
                            }

                            &_uploaded{
                                @include flex-center-center;
                                padding: 10px 5px;
                                cursor: pointer;
                                .file-icon{
                                    font-size: 1.2rem;
                                }
                            }
                        }
                    }

                    .candidate_main-additional_info{
                        margin: auto;
                        margin-top: 2rem;
                        font-size: 1.5rem;
                        font-weight: 700;
                        color: $appButtonHoverDark;
                    }
                }
            }

            &-listing{
                margin: 2rem 0;
            }

            &-action{
                @include flex-center-center;
                flex-direction: row;
                padding: 10px;
                &_button{
                    margin: 0;
                    max-width: 50%;
                    min-width: 30%;
                }

                .save_as_draft{
                    text-decoration: underline;
                    color: $appButtonHoverDark;
                }
            }
        }
    }
}

//smaller screens
@include media('<=desktop') {
    
}

@include media('<=tablet') {

}

@include media('<=phone') {

}