@import '../../../mediaQueries';
@import '../../../mixins';
@import '../../../colorThemes';

.assessmentFeedback_container{
  .assessmentFeedback_wrapper{
    padding: 20px;
    .assessmentFeedback_main{
      padding: 10px;
      &-message{
        margin: 10px;
        font-size: clamp(1.1rem, 0.4203rem + 1.4215vw, 2.2rem);
      }

      &-header_container{
        margin: 10px 0;
        margin-bottom: 30px;
        @include flex-center-spacebetween;
        .assessmentFeedback_main_header{
          color: $appThemeText;
          font-weight: 500;
          &_text{
            font-size: clamp(1rem, 0.8rem + 1vw, 2rem);
            text-transform: capitalize;

            .subheader{
                color: $appThemeDark;
            }
          }
          @include flex-center-spacebetween;
        }

        .assessmentFeedback_main_header_actions{
          @include flex-center-flexend;
          max-width: 52%;
          cursor: pointer;
          
          &-search, &-filter{
            margin-left: 10px;
            font-size: 0.9rem;
            button{
              font-weight: 600;
            }
          }
        }
      }
      &-video_summary, &-written_summary, &-additional_comments{
          margin: 1rem 0;
          &-header{
              margin: 1.5rem 0;
              color: $appThemeDark;  
          }

          &-content{
            @include flex-start-spacebetween;
            .video_summary-container{
              width: 50%;
            }
            .video_summary-detail{
                width: 40%;
                &-bp, &-date, &-rating{
                  font-weight: 500;
                  margin-bottom: 1.2rem;
                  h4{
                    color: $appThemeDark;
                  }
                  &_select{
                    &-wrap{
                      margin: 1rem 0;
                      label{
                        margin-left: 10px;
                      }
                    }
                  }
                }
            }
          }
      }

      &-actions{
        margin: 1.5rem 0;
        @include flex-center-center;
        &_btn{
            margin: 1rem 5px;
          }
      }
    }
  }
}

//smaller screens
@include media('<=desktop') {
  .assessmentFeedback_container{
    .assessmentFeedback_wrapper{
      .assessmentFeedback_main{
        &-grid{
          gap: 20px;
  
          .item{
            padding: 10px;
  
            .item_content{
              padding: 10px;
            }
          }
        }
      }
    }
  }
}

@include media('<=tablet') {
  .assessmentFeedback_container{
    .assessmentFeedback_wrapper{
      .assessmentFeedback_main{
        padding: 20px;
        &-message{
          // font-size: clamp(1.3rem, 0.5203rem + 1.7215vw, 2.5rem);
        }
  
        &-grid{
          // max-width: 75vw;
          display: grid;
          grid-template-columns: repeat(2, auto);
          grid-gap: 20px;
          padding: 20px 0;
  
          .item{
            // @include flex-center;
            // max-width: 25vw;  
            padding: 15px;
            background-color: $appThemeBackground;
            text-align: center;
  
            .item_content{
              padding: 15px;
              .content_text{
                // font-size: clamp(1rem, 0.7857rem + 0.5714vw, 1.5rem);
              }
  
              .content_number{
                font-size: clamp(3rem, 2.5000rem + 1.3333vw, 4rem);
                color: #5774F9;
              }
            }
          }
        }
      }
    }
  }
}

@include media('<=phone') {
  .assessmentFeedback_container{
    .assessmentFeedback_wrapper{
      .assessmentFeedback_main{
        &-message{
          @include flex-center-center;
        }
  
        &-grid{
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(4, auto);
  
          .item{
            padding: 15px;
          }
        }
  
        &_header{
          margin-top: 10px;
          @include flex-center-center;
        }
  
      }
    }
  }
}