@use "../../../mixins";
@import "../../../colorThemes";
@import "../../../mediaQueries";
@import "../../../animate.min.css";

.org_signup {
    background-color: $appThemeBackground;
    padding: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 100px);

    @media (max-width: $tablet) {
        padding: 20px;
    }

    @media (max-width: $phone) {
        padding: 10px;
        height: unset;
        display: block;
    }

    .org_signup_main {
        padding: 20px;
        border-radius: 15px;
        background-color: #ffffff;
        height: 100%;

        @media (max-width: $phone) {
            padding: 10px;
            height: unset;
        }

        &_child {
            height: 100%;
            
            @media (max-width: $phone) {
                height: unset;
            }

            @media (max-width: $tablet) {
                height: unset;
            }

            &_row {
                height: 100%;
                display: flex;
                
                ::-webkit-scrollbar {
                    width: 10px;
                    border-radius: 50px;
                }
                
                /* Track */
                ::-webkit-scrollbar-track {
                    background: #f1f1f1;
                    border-radius: 50px;
                }
                
                /* Handle */
                ::-webkit-scrollbar-thumb {
                    background: $appThemeDark;
                    border-radius: 50px;
                }
                
                /* Handle on hover */
                ::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }

                @media (max-width: $phone) {
                    height: unset;
                }

                @media (max-width: 991px) {
                    display: block;
                }

                &_left {
                    width: 50%;
                    height: 100%;

                    @media (max-width: 991px) {
                        width: 100%;
                    }

                    &_profile {
                        background-image: url('../../../../public/images/profile-bg.png');
                        background-size: cover;
                        border-radius: 15px;
                        overflow: hidden;
                        background-position: bottom center;
                        padding-bottom: 20px;
                        text-align: center;
                        height: 97%;

                        img {
                            max-width: 500px;
                            margin: auto;
                            display: block;

                            @media (max-width: $desktop) {
                                max-width: 400px;
                            }

                            @media (max-width: $laptop) {
                                max-width: 340px;
                            }

                            @media (max-width: $phone) {
                                max-width: 94%;
                            }
                        }

                        &_content {
                            max-width: 490px;
                            margin: 0px auto;
                            padding: 0 20px;

                            @media (max-width: $desktop) {
                                max-width: 440px;
                            }

                            h3 {
                                font-size: 45px;
                                line-height: 1.1;
                                color: #ffffff;
                                font-weight: 500;
                                margin-bottom: 20px;

                                @media (max-width: $desktop) {
                                    font-size: 34px;
                                }

                                @media (max-width: $phone) {
                                    font-size: 28px;
                                }
                            }

                            p {
                                font-size: 20px;
                                color: #ffffff;
                                font-weight: 500;
                                line-height: 1.5;

                                @media (max-width: $desktop) {
                                    font-size: 18px;
                                }

                                @media (max-width: $phone) {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }

                &_right {
                    width: 50%;
                    height: 100%;
                    overflow-y: scroll;
                    @media (max-width: 991px) {
                        width: 100%;
                    }

                    @media (max-width: $phone) {
                        display: none;
                        overflow-y:unset;
                    }
                    @media (max-width: $tablet) {
                        overflow-y:unset;
                    }
                    
                    &_top {
                        text-align: center;
                        padding-top: 25px;
                        margin-bottom: 25px;

                        img {
                            max-width: 30%;

                            @media (max-width: $desktop) {
                                max-width: 250px;
                            }

                            @media (max-width: 991px) {
                                display: none;
                            }
                        }

                        h3 {
                            font-size: 32px;
                            color: #5265f9;
                            font-weight: 500;
                            margin-top: 50px;

                            @media (max-width: $desktop) {
                                font-size: 26px;

                                margin-top: 20px;
                            }
                        }
                    }

                    &_form,&_form-submitted {
                        max-width: 60%;
                        margin: auto;
                        padding-bottom: 30px;

                        &-mandatory_text{
                            font-weight: 500;
                            text-align: end;
                        }

                        &_wrapper{
                            font-size: 1.5rem;
                            text-align: center;
                        }

                        &-input-wrapper{
                            margin-bottom: 20px;
                        }

                        @media (max-width: $desktop) {
                            max-width: 60%;
                        }

                        @media (max-width: $laptop) {
                            max-width: 370px;
                        }

                        @media (max-width: 991px) {
                            max-width: 100%;
                        }


                        form {
                            margin-left: 20px;

                            @media (max-width: 991px) {
                                margin-left: 0px;
                            }
                        }

                        &_checkwrp {
                            display: flex;
                            input[type="checkbox"]{
                                height: 1.2rem;
                                width: 1.2rem;
                                cursor: pointer;
                            }
                            a {
                                color: #5265f9;
                            }
                        }

                        &_btm {
                            margin: 35px 0 55px;
                            text-align: center;

                            button {
                                font-size: 16px;
                                color: white;
                                font-weight: 500;
                                height: 53px;
                                border-radius: 10px;
                                width: 100%;
                                background-color: #5265f9;
                                transition: all 0.3s ease-in;

                                &:hover {
                                    box-shadow: 0px 0px 20px 0px #00000029;
                                    background: #465de6;
                                }
                            }

                            p {
                                font-size: 18px;
                                color: #414a53;
                                font-weight: 500;
                                margin-top: 20px;

                                a {
                                    color: #5265f9;
                                }
                            }


                        }

                        &_ftr {
                            p {
                                font-size: 14px;
                                color: #414a53;
                                font-weight: 500;
                                margin-top: 20px;
                                text-align: center;

                                a {
                                    color: #5265f9;
                                }
                            }
                        }
                    }
                }
            }

            &_mobile__logo {
                display: none;

                @media (max-width: 991px) {
                    display: block;

                    img {
                        display: block;
                        margin: auto;

                    }
                }
            }
        }
    }
}