@import '../../../../colorThemes';
@import '../../../../mixins';

.assessment_listing{
    margin: auto;
    &-header{
        font-size: 1.5rem;
        font-weight: 700;
        color: $appButtonHoverDark;
        width: fit-content;
        margin: auto;
    }

    &-table{
        margin: 2rem 0;
    }
}