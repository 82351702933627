@use "../../mixins";
@import "../../colorThemes";
@import "../../mediaQueries";


.loginpage-container {
    background-color: $appThemeBackground;
    box-sizing: border-box;
    height: 100vh;
    position: relative;
    padding: 50px;

    @media (max-width: $phone) {
        padding: 1rem;
        height: max-content;
        height: 100vh;
    }

    .loginpage {
        display: flex;
        border-radius: 10px;
        background-color: $appThemeWhite;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-height: 75vh;
        width: 90vw;
        padding: 20px;

        @media (max-width: $phone) {
            display: unset;
            height: 95vh;
            width: 90vw;
            padding: 10px;
        }

        @media (max-width: $tablet) {
            height: fit-content;
            min-height: unset;
        }


        @media (max-width: $smallTablet) {
            height: fit-content;
            min-height: unset;
        }

        &__child {
            width: 50%;
            border-radius: 10px;
            // padding: 10px;
            @include mixins.flex-center-spacearound;
            flex-direction: column;

            @media (max-width: $tablet) {
                // padding: 20px;
                min-width: calc(50% - 40px);
            }

            @media (max-width: $phone) {
                padding: unset;
                width: unset;
                display: block;
                border-radius: unset;
            }

            &__textbox {
                padding: 1rem;
                min-width: 220px;
                line-height: 1;
                overflow: none;
                max-width: 70%;

                @media (max-width: $phone) {
                    max-width: unset;
                    padding: unset;
                    min-width: unset;
                }

                @media (max-width: $tablet) {
                    max-height: 80vh;
                    min-width: 200px;
                }

                .aptillio-logo {
                    text-align: center;

                    img {
                        max-width: 40%;
                        min-width: 40%;

                        @media (max-width: $tablet) {
                            max-width: 35%;
                            min-width: 35%;
                        }
                    }
                    @media (max-width: $tablet) {
                        margin: 0;
                        padding: 0;
                    }
                }

                .loginpage__heading {
                    @include mixins.flex-center-center;
                    color: $appThemeDark;
                    font-size: 1.5rem;
                    font-weight: normal;

                    h3 {
                        margin: 1.2rem;
                        @media (max-width: $tablet) {
                            margin: 1rem;
                            font-size: 1.2rem;
                        }

                        @media (max-width: $smallTablet) {
                            margin: 0.3rem;
                            font-size: 1.2rem;
                        }
                    }
                }

                .loginpage_form{
                    &-input{
                        margin-bottom: 1.25rem;
                        @media (max-width: $tablet) {
                            margin-bottom: 0.6rem;
                        }

                        input{
                            @media (max-width: $tablet) {
                                font-size: 0.6rem;
                                height: 1.7rem;
                                padding-left: 10px;
                                img{
                                    font-size: 2px;
                                }
                            }

                            @media (max-width: $smallTablet) {
                                height: 1.4rem;
                            }
                        }

                        label{
                            @media (max-width: $tablet) {
                                font-size: 0.7rem;
                                margin-left: 0 !important;
                                
                            }
                            @media (max-width: $smallTablet) {
                                font-size: 0.6rem;
                                margin-left: 0 !important;
                            }
                        }
                    }
                    &-submit{
                        margin-bottom: 1.25rem;

                        button{
                            @media (max-width: $tablet) {
                                height: 1.5rem;
                                font-size: 0.7rem;
                            }
                        }
                        @media (max-width: $tablet) {
                            margin-bottom: 1rem;
                        }
                    }
                }

                .redirect_actions {
                    a {
                        color: $appThemeDark;
                        font-weight: 500;
                    }
                    &-forget,&-signup {
                        text-align: center;
                        margin-bottom: 20px;
                        font-size: 1rem;
                        @include mixins.flex-center-spacebetween;
                        p {
                            font-weight: 500;
                        }

                        .remember-me {
                            display: flex;
                            text-align: initial;

                            input {
                                cursor: pointer;
                                margin-right: 0.5rem;
                                border-radius: 10px;
                                height: 17px;
                                width: 17px;

                                @media (max-width: $tablet) {
                                    height: 12px;
                                    width: 12px;
                                    margin-right: 0.3rem;
                                }
                                @media (max-width: $smallTablet) {
                                    height: 10px;
                                    width: 10px;
                                    margin-right: 0.2rem;
                                }
                            }

                            @media (max-width: $phone) {
                                @include mixins.flex-center-center;
                            }
                        }

                        @media (max-width: $laptop) {
                            font-size: 0.8rem;
                        }
                        @media (max-width: $tablet) {
                            font-size: 0.7rem;
                        }

                        @media (max-width: $smallTablet) {
                            font-size: 0.5rem;
                            margin-bottom: 5px;
                        }

                        @media (max-width: $phone) {
                            font-size: 0.7rem;
                        }                        
                    }

                    &-signup{
                        @include mixins.flex-center-center;
                    }

                }
            }

            &-privacypolicy {
                width: 60%;
                min-width: 200px;
                text-align: center;
                font-size: 0.8rem;

                a {
                    color: $appThemeDark;
                }

                @media (max-width: $tablet) {
                    font-size: 0.6rem;
                }

                @media (max-width: $smallTablet) {
                    font-size: 0.45rem;
                }

                @media (max-width: $phone) {
                    width: unset;
                    min-width: none;
                    margin-top: 2rem;
                }
            }

            &__profile-left {
                background-image: url('../../../public/images/profile-bg.png');
                background-size: cover;
                border-radius: 15px;
                overflow: hidden;
                background-position: bottom center;
                width: 100%;
                max-height: 100%;
                // background-color: $appThemeDark;
                @include mixins.flex-center-center;
                position: relative;

                img {
                    width: 100%;
                    // height: calc(100% - 10px);
                    @media (max-width: $tablet) {
                        width: 100%;
                    }
                }

                &_logo_text {
                    position: absolute;
                    color: $appThemeWhite;
                    font-family: 'Quicksand', sans-serif;
                    max-width: 80%;
                    width: 70%;
                    bottom: 10%;
                    text-transform: capitalize;
                    text-align: center;

                    .text__heading {
                        font-size: 2rem;
                        font-weight: 500;
                        margin: auto;
                        max-width: 340px;
                        margin-bottom: 10px;
                        @media (max-width: $desktop) {
                            font-size: 34px;
                        }

                        @media (max-width: $tablet) {
                            font-size: 1.5rem;
                        }

                        @media (max-width: $phone) {
                            font-size: 28px;
                        }
                    }

                    .text_subheading {
                        font-size: 1.1rem;
                        font-weight: 500;

                        @media (max-width: $tablet) {
                            font-size: 0.8rem;
                        }
                    }
                }
            }

            // &-left{

            // }
            &-right {
                background-color: $appThemeWhite;
                padding: 30px 20px;
            }
        }
    }
}
