@import '../../../mediaQueries';
@import '../../../mixins';
@import '../../../colorThemes';

.candidateFeedback_container{
  .candidateFeedback_wrapper{
    padding: 0 10px;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    .candidateFeedback{
      &_details{
        width: 30%;
        margin: 1rem;
        background-color: $appThemeWhite;
        color: $appThemeText;
            &-content{
              padding: 1.2rem;
                &_header{
                    text-align: center;
                    margin: 1.2rem 0;
                    .header{
                        font-weight: 600;
                    }
                }
                &_form{
                    margin: auto;
                    .candidateFeedback_details-form_input_wrapper{
                        @include flex-center-spacebetween;
                        &-label{
                            padding: 1rem;
                            font-weight: bold;
                            max-width: 25%;
                        }
                        &-input{
                            padding: 1rem;
                            font-weight: 400;
                            font-size: 0.9rem;
                            .view_textarea-text{
                              padding: unset;
                              margin-left:20px;
                              @include flex-center-center;
                              cursor: pointer;
                              span{
                                font-size: 1.5rem;
                              }
                            }
                        }
                    }
                }
                &_notes{
                  padding: 1rem;
                  .notes-header{
                      padding: 1.2rem 1rem;
                      color: $appThemeDark;
                      text-align: center;
                  }
                }
            }
            &-submit{
              padding: 1rem;
              width: 50%;
              margin: auto;
            }
      }
    }
    .candidateFeedback_main{
      padding: 1rem;
      margin: 1rem;
      width: 70%;
      background-color: $appThemeWhite;
      &-header_container{
        // margin: 10px 0;
        @include flex-center-center;
        .candidateFeedback_main_header{
          color: $appThemeText;
          padding: 1rem;
          font-weight: 500;
          &_text{
            font-size: clamp(1rem, 0.8rem + 1vw, 2rem);
            text-transform: capitalize;
          }
          @include flex-center-spacebetween;
        }
      }
      &-summary, &-recording, &-rating{
          padding: 1rem;
          @include flex-start-spacebetween;
          &_label{
              max-width: 25%;
              margin: 1rem 0;
              color: $appThemeDark;
              width: 30%;
          }
          &_container{
            width: 65%;
            .rating_select{
              &-wrap{
                margin: 1rem 0;
                @include flex-center-flexstart;
                input{
                  height: 1rem;
                  width: 1rem;
                }
                label{
                  margin-left: 10px;
                  font-weight: 600;
                }
              }
            }

            .recording_btn{
              max-width: 30%;
              min-width: 150px;
            }
          }
      }

      &-actions{
        margin: 1.5rem 0;
        @include flex-center-center;
        &_btn{
            margin: 1rem 5px;
          }
      }
    }
  }
}