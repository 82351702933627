@import "../../../colorThemes";
@import "../../../mixins";

.privacy_policy{
    // border: 20px solid opacify($color: #000000, $amount: 0);
    position: relative;
    background: $appThemeWhite;
    overflow: hidden;
    height: 90vh;
    padding: 1rem;
    &-wrapper{
        max-height: 100%;
        padding: .5rem;
        height: 100%;
        // overflow: scroll;
        /* width */
        ::-webkit-scrollbar {
            width: 10px;
            border-radius: 50px;
        }
        
        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 50px;
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: $appButtonHoverDark;
            border-radius: 50px;
        }
        
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        &-heading_main{
            text-align: center;
            padding: 15px;
            font-size: 2rem;
            font-weight: 500;
        }

        &-heading_sub{
            text-align: center;
            padding: 15px;
            font-size: 1.5rem;
            font-weight: 500;
            color: $appButtonHoverDark;
        }

        &-content{
            padding: 15px;
            // min-height: max-content;
            height: 70%;
            overflow-y: scroll;
            
            &-heading{
                text-align: center;
                padding: 15px;
                color: $appButtonHoverDark;
                font-weight: 1.2rem;
                font-weight: 600;
            }
            &-paragraph{
                word-break: break-all;
                padding: 15px;
                font-size: 1rem;
            }
        }
    }
}

.dialogue_box-close{
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 8px;
    color: $appThemeWhite;
    border-radius: 50%;
    background-color: $appThemeDark;
    @include flex-center-center;
}

.bold-text{
    font-size: 1.2rem;
    font-weight: bold;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    overflow-y: unset !important;
}